<template>
  <b-card title="Asignar vehículo">
    <b-row>
      <b-col>
        <div class="form-group">
          <label>Placa</label>
          <input
            v-model="form.placa"
            type="text"
            disabled
            class="form-control form-control-sm"
            placeholder="Código"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Marca</label>
          <select
            class="form-control form-control-sm"
            v-model="form.marca"
            disabled
          >
            <option :value="null">Seleccione</option>
            <option
              :value="item.id_marca"
              v-for="(item, idx) in marcas"
              :key="idx"
            >
              {{ item.descripcion }}
            </option>
          </select>
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Tipo de vehículo</label>
          <Select2 v-model="form.tipo" :options="tvehiculos" disabled />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Modelo</label>
          <input
            v-model="form.modelo"
            disabled
            min="0"
            type="number"
            class="form-control form-control-sm"
            placeholder="0000"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Estado</label>
          <input
            disabled
            :class="
              'form-control form-control-sm ' +
              (form.estado == 1 ? 'is-valid' : 'is-invalid')
            "
            :value="form.estado == 1 ? 'DISPONIBLE' : 'NO DISPONIBLE'"
          />
        </div>
      </b-col>
    </b-row>
    <div class="separator separator-dashed mt-3 mb-3"></div>
    <b-row>
      <b-col md="4">
        <label> Asignar a Técnico</label>
        <Select2 v-model="form.brigada" :options="clients" />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <div class="form-group">
          <button
            class="btn btn-sm btn-circle btn-link"
            @click="
              () => {
                $router.go(-1);
              }
            "
          >
            <i class="la la-reply"></i> Regresar
          </button>
          <button
            class="btn btn-sm btn-primary btn-circle"
            @click="assignVehicle()"
          >
            <i class="flaticon2-writing"></i>
            {{
              loading
                ? "Cargando..."
                : form.estado == 1
                ? "Asignar"
                : "Actualizar"
            }}
          </button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
import Select2 from "v-select2-component";
import toastr from "toastr";
import axios from "axios";
export default {
  components: {
    Select2,
  },
  data() {
    return {
      id: this.$route.params.id,
      show: false,
      loading: false,
      tvehiculos: [],
      clients: [],
      marcas: [],
      form: {
        placa: null,
        marca: null,
        modelo: null,
        tipo: -1,
        brigada: -1,
      },
    };
  },
  mounted() {
    this.loadTipoVehiculo();
    this.loadMarcas();
    this.loadVehiculo();
    this.loadTech();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    loadTech() {
      axios
        .get(`/clientes`)
        .then((res) => {
          let client = [];
          client.push({ id: -1, text: "Seleccione" });
          res.data.map((el) => {
            client.push({
              id: el.nif,
              text: `${el.nif} - ${el.nombre}`,
            });
          });
          this.clients = client;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadVehiculo() {
      axios
        .get(`/vehiculos/${this.id}`)
        .then((res) => {
          const [data] = res.data;
          this.form = data;
          this.form.tipo = data.id_tipovehiculo;
          this.form.marca = data.id_marca;
          this.form.brigada = data.brigada ? data.brigada : -1;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadMarcas() {
      axios
        .get(`/vehiculo-marcas`)
        .then((res) => {
          this.marcas = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadTipoVehiculo() {
      axios
        .get(`/vehiculo-tipos`)
        .then((res) => {
          this.tvehiculos = [];
          let tv = [];
          res.data.map((el) => {
            tv.push({
              id: el.id_tipovehiculo,
              text: el.descripcion.toUpperCase(),
            });
          });
          this.tvehiculos = tv;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    assignVehicle() {
      if (this.form.brigada !== -1) {
        this.loading = true;
        axios
          .put(`/vehiculos/${this.id}`, this.form)
          .then((res) => {
            if (res.data.message == "success") {
              this.loadVehiculo();
              this.$swal.fire({
                title: "Información",
                text: res.data.information,
                icon: "success",
              });
            } else {
              this.$swal.fire({
                title: "Información",
                text: res.data.information,
                icon: "warning",
              });
            }
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      } else {
        toastr.info(
          `Debe seleccionar una brigada para ${
            this.form.estado == 1 ? "asignar" : "actualizar"
          } el vehículo `
        );
      }
    },
  },
};
</script>
